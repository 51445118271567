<ng-container *ngLet="{
  partCount: partCount$ | async,
  loadingResults: loading$ | async,
  clearFilters: clearFilters$ | async
} as vm">
  <div id="partBase_div_LoadingPartsData" *ngIf="vm.loadingResults">
    <h5>Loading part(s) ...</h5>
  </div>

  <div id="partBase_div_PartsData">
    <part-list id="partBase_part-list_PartSearchTerm" *ngIf="vm.partCount > 1"
      [partsData]="parts$ | async"
      [partSearchTerm]="partSearchTerm$ | async"
      [currentPage]="currentPage$ | async"
      [pageSize]="pageSize"
      [branch]="branch$ | async"
      [facets]="facets$ | async"
      [loading]="vm.loadingResults"
      (updateFacets)="updateFacets($event)" (updatePage)="updatePage($event)" (selectPart)="goToPart($event)">
    </part-list>
    <part-details id="partBase_part-details_PartsData" *ngIf="vm.partCount == 1" [partSearchTerm]="partSearchTerm$ | async" [partDetail]="parts$ | async"></part-details>
  </div>

  <create-part *ngIf="showCreatePart$ | async" [partSearchTerm]="partSearchTerm$ | async" (dismiss)="dismissCreatePart()"></create-part>
</ng-container>

