<div class="row g-0">
  <div *ngIf="partsData$ | async as partsData" class="col-12 col-md-3">
    <div class="title-of-box-hold">
      <h5 class="strong">Refine Search</h5>
    </div>
    <div class="bg-white border p-4">
      <div id="partList_div_in_stock" tabindex="-1" class="py-1" *ngIf="isInternal$ | async">
        <input id="partList_checkbox_in_stock" tabindex="-1" type="checkbox" name="in-stock" [(ngModel)]="inStockOnly" value="option" (change)="onInStockChange($event)">
        <label tabindex="-1" for="partList_checkbox_in_stock" class="m-0">
          Show In-Stock Parts Only
        </label>
      </div>
      <h5>Manufacturers</h5>
      <div class="pb-1">
        <input type="text" class="form-control" placeholder="Enter Manufacturers" ngModel (ngModelChange)="onManufacturerFilterChange($event)" [ngModelOptions]="{ standalone: true }">
      </div>
      <div id="partList_div_partDataFacetsManufacturers" tabindex="-1" *ngIf="partsData.facets && partsData.facets.manufacturer.buckets">
        <div id="partList_div_partDataFacetsManufacturer_{{i}}" tabindex="-1" *ngFor="let manufacturer of manufacturerBuckets$ | async; let i=index" class="py-1">
          <input id="partList_checkbox_Manufacturer_{{i}}" tabindex="-1" type="checkbox" name="field" value="option"
            [(ngModel)]="manufacturer.selected" (change)="onFacetChange(partsData.facets)">
          <label tabindex="-1" for="partList_checkbox_Manufacturer_{{i}}" class="m-0">{{manufacturer.term!=''?
            manufacturer.term:'Other'}} ({{manufacturer.count | number:'.0'}})
          </label>
        </div>
      </div>
      <hr>
      <h5>Category</h5>
      <div id="partList_div_partDataFacetsCategories" tabindex="-1" *ngIf="partsData.facets && partsData.facets.category.buckets">
        <div id="partList_div_partDataFacetsCategory_{{i}}" tabindex="-1" *ngFor="let category of partsData.facets.category.buckets; let i=index" class="py-1">
          <input id="partList_checkbox_Category_{{i}}" tabindex="-1" type="checkbox" name="field" value="option"
            [(ngModel)]="category.selected" (change)="onFacetChange(partsData.facets)">
          <label tabindex="-1" for="partList_checkbox_Category_{{i}}" class="m-0">{{category.term!=''?
            category.term:'Other'}} ({{category.count | number:'.0'}})</label>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12 col-md-9 ps-md-3">
    <div class="title-of-box-hold">
      <h5 class="strong">{{ totalParts$ | async | number:'.0' }} parts found for <span class="text-primary">"{{partSearchTerm}}"</span></h5>
    </div>

    <div>
      <div class="p-3 gx-1">
        <span>Filters:</span>
        <span id="partList_span_NoFiltersApplied" *ngIf="!selectedFacets.length" class="badge bg-medium">No filters applied</span>
        <ng-container *ngFor="let facet of selectedFacets">
          <span id="partList_span_Refine_{{i}}" *ngFor="let refinement of facet.terms; let i = index" class="badge bg-medium">{{refinement != '' ? refinement : 'Other'}}</span>
        </ng-container>
      </div>
      <div *ngIf="!inStockOnly; else inStockOnlyContainer">
        <div *ngIf="partsData$ | async as partsData" class="product-list gy-3" id="product-list-container">
          <!--Product List Card Start-->
          <div id="partList_div_PartsData" class="product-card border rounded-lg row g-0 bg-white" [ngClass]="{ 'disabled-card': !part.quantityAvailable }" *ngFor="let part of partsData.parts; let i = index;">
            <div class="col-sm-12 col-md-4 col-lg-4">
              <a id="partList_div_RushPartNumber" href="javascript:void(0)" (click)="selectPart(part)">
                <h4 class="product-card-title m-0 px-3 py-2 d-flex justify-content-between" [ngClass]="{ 'highlighted': part.partNumber === highlightPart?.partNumber && part.rushPartNumber === highlightPart?.rushPartNumber }" #productCardTitle>
                  <span class="strong">
                    {{ part.rushPartNumber || part.number }}
                  </span>
                  <div>
                    <a id="partList_a_RemoveFromFavorites" *ngIf="part.isFavoritePart" href="javascript:void(0)" title="Remove from Favorites"
                      (click)="removeFavoritePart(part); $event.stopPropagation()">
                      <i class="fas fa-star"></i>
                    </a>
                    <a id="partList_a_AddToFavorites" *ngIf="!part.isFavoritePart" href="javascript:void(0)" title="Add to Favorites"
                      (click)="addToFavoriteParts(part); $event.stopPropagation()">
                      <i class="far fa-star"></i>
                    </a>
                    &nbsp;
                    <a id="partList_a_CopyPartNumberToClipboard" href="javascript:void(0)" title="Copy Part Number to Clipboard"
                      (click)="copyPartNumberToClipBoard(part); $event.stopPropagation()">
                      <i class="far fa-clone"></i>
                    </a>
                  </div>
                </h4>
                <div class="product-image product-image-md" tabindex="-1">
                  <img [src]="getImage(part.image, imageBaseUrl, '100X100')" [default]="'assets/img/100X100_No_Image.png'" tabindex="-1" alt="No Image" >
                </div>
              </a>
            </div>
            <div class="col-sm-12 col-md-5 col-lg-5 border-start border-end">
              <div class="border-bottom px-3 d-flex align-items-center text-truncate" [ngStyle]="{ 'height.px': productCardTitle.offsetHeight }">
                <b>{{part.quantityAvailable | number:'.0'}} Available</b> - {{branch.code}} - {{part.branchName}}
              </div>
              <div>
                <div class="p-3 row">
                  <div class="col-6">
                    <b>VMRS Description</b>
                    <div class="text-truncate" title="{{part.vmrsDescription}}">{{part.vmrsDescription}}</div>
                  </div>
                  <div class="col-6">
                    <b>VMRS Code</b>
                    <div>{{part.vmrsCode}}</div>
                  </div>
                </div>
                <div class="p-3 row">
                  <div *ngIf="part.corePart" class="col-6">
                    <b>Core No.</b>
                    <div>{{part.corePart}}</div>
                  </div>
                  <div class="col-6">
                    <b>Item Summary</b>
                    <div>{{part.description}}</div>
                  </div>
                </div>
                <div *ngIf="part.comments" class="p-3 row">
                  <div class="col-12">
                    <h5 class="strong"></h5>
                    <p>Your search of {{part.comments}}</p>
                  </div>
                </div>
                <div class="p-4 col-12" *ngIf="part.corePart !== null && part.corePart !== ''">
                  <select id="part_search_select_core_{{i}}" class="form-control core-options" [ngModel]="part.coreOption" (ngModelChange)="selectCoreReturn($event, part);">
                    <option [value]="'NOCORER'">No Core Returned</option>
                    <option [value]="'CORE1'">Core Returned</option>
                  </select>
                </div>
                <div *ngIf="part.hazmat" class="p-3 row">
                  <div class="alert alert-danger col-11 ms-3 mb-0" id="hazmat-errors">
                    <span><i class="fas fa-exclamation-triangle"></i> Hazmat products not eligible for freight delivery. In-store pickup and local delivery are still available for this product.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3 d-flex align-items-center flex-wrap couponPrice">
              <pricing id="partList_pricing_Part" [part]="part" [branch]="branch" (extend)="onExtendPart($event)" [displayType]="displayType"></pricing>
              <ng-container *ngIf="displayType === 'back-counter-tool'">
                <part-quantity  [value]="part.quantity" (onChangeQuantity)="changeQuantity($event, part)" [min]="1" [max]="10000"></part-quantity>
                <button class="btn btn-primary" (click)="addToRequest(part);" [disabled]="!part.isRushPart || (part.isRushPart && !part.isExtendedToBranch)">Add To Request</button>
              </ng-container>
            </div>
            <!-- ToDo: national inventory url/href need to correct. -->
            <div id="partList_div_IsQuote" *ngIf="!(isQuote$ | async) && part.isRushPart" class="col-12 border-top p-2 d-flex justify-content-center">
              <a id="partList_div_NationalInventory" class="hyperlink" href="javascript:void(0)" (click)="openNationalInventory(part)">National Inventory</a>
            </div>
          </div>
          <!--Product List Card End-->
        </div>
        <pagination [currentPage]="currentPage" idPrefix="partList" [totalCount]="totalParts$" [pageSize]="pageSize" (currentPageChange)="setPage($event); scrollToTop(); showLoader();"></pagination>
      </div>
      <ng-template #inStockOnlyContainer>
        <div *ngIf="allParts$ | async as partsData" class="product-list product-list-in-stock gy-3" (scroll)="onScroll($event)">
          <!--Product List Card Start-->
          <div id="partList_div_instock_PartsData" class="product-card border rounded-lg row g-0 bg-white" [ngClass]="{ 'disabled-card': !part.quantityAvailable }" *ngFor="let part of partsData.parts; let i = index;">
            <div class="col-sm-12 col-md-4 col-lg-4">
              <a id="partList_div_RushPartNumber" href="javascript:void(0)" (click)="selectPart(part)">
                <h4 class="product-card-title m-0 px-3 py-2 d-flex justify-content-between" [ngClass]="{ 'highlighted': part.partNumber === highlightPart?.partNumber && part.rushPartNumber === highlightPart?.rushPartNumber }" #productCardTitle>
                  <span class="strong">
                    {{ part.rushPartNumber || part.number }}
                  </span>
                  <div>
                    <a id="partList_a_RemoveFromFavorites" *ngIf="part.isFavoritePart" href="javascript:void(0)" title="Remove from Favorites"
                      (click)="removeFavoritePart(part); $event.stopPropagation()">
                      <i class="fas fa-star"></i>
                    </a>
                    <a id="partList_a_AddToFavorites" *ngIf="!part.isFavoritePart" href="javascript:void(0)" title="Add to Favorites"
                      (click)="addToFavoriteParts(part); $event.stopPropagation()">
                      <i class="far fa-star"></i>
                    </a>
                    &nbsp;
                    <a id="partList_a_CopyPartNumberToClipboard" href="javascript:void(0)" title="Copy Part Number to Clipboard"
                      (click)="copyPartNumberToClipBoard(part); $event.stopPropagation()">
                      <i class="far fa-clone"></i>
                    </a>
                  </div>
                </h4>
                <div class="product-image product-image-md" tabindex="-1">
                  <img [src]="getImage(part.image, imageBaseUrl, '100X100')" [default]="'assets/img/100X100_No_Image.png'" tabindex="-1" alt="No Image" >
                </div>
              </a>
            </div>
            <div class="col-sm-12 col-md-5 col-lg-5 border-start border-end">
              <div class="border-bottom px-3 d-flex align-items-center text-truncate" [ngStyle]="{ 'height.px': productCardTitle.offsetHeight }">
                <b>{{part.quantityAvailable | number:'.0'}} Available</b> - {{branch.code}} - {{part.branchName}}
              </div>
              <div>
                <div class="p-3 row">
                  <div class="col-6">
                    <b>VMRS Description</b>
                    <div class="text-truncate" title="{{part.vmrsDescription}}">{{part.vmrsDescription}}</div>
                  </div>
                  <div class="col-6">
                    <b>VMRS Code</b>
                    <div>{{part.vmrsCode}}</div>
                  </div>
                </div>
                <div class="p-3 row">
                  <div *ngIf="part.corePart" class="col-6">
                    <b>Core No.</b>
                    <div>{{part.corePart}}</div>
                  </div>
                  <div class="col-6">
                    <b>Item Summary</b>
                    <div>{{part.description}}</div>
                  </div>
                </div>
                <div *ngIf="part.comments" class="p-3 row">
                  <div class="col-12">
                    <p>Your search of {{part.comments}}</p>
                  </div>
                </div>
                <div class="p-4 col-12" *ngIf="part.corePart !== null && part.corePart !== ''">
                  <select id="part_search_select_core_{{i}}" class="form-control core-options" [ngModel]="part.coreOption" (ngModelChange)="selectCoreReturn($event, part);">
                    <option [value]="'NOCORER'">No Core Returned</option>
                    <option [value]="'CORE1'">Core Returned</option>
                  </select>
                </div>
                <div *ngIf="part.hazmat" class="p-3 row">
                  <div class="alert alert-danger col-11 ms-3 mb-0" id="hazmat-errors">
                    <span><i class="fas fa-exclamation-triangle"></i> Hazmat products not eligible for freight delivery. In-store pickup and local delivery are still available for this product.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3 d-flex align-items-center flex-wrap couponPrice">
              <pricing id="partList_pricing_Part" [part]="part" [branch]="branch" (extend)="onExtendPart($event)" [displayType]="displayType"></pricing>
              <ng-container *ngIf="displayType === 'back-counter-tool'">
                <part-quantity  [value]="part.quantity" (onChangeQuantity)="changeQuantity($event, part)" [min]="1" [max]="10000"></part-quantity>
                <button class="btn btn-primary" (click)="addToRequest(part);" [disabled]="!part.isRushPart || (part.isRushPart && !part.isExtendedToBranch)">Add To Request</button>
              </ng-container>
            </div>
            <div id="partList_div_IsQuote" *ngIf="!(isQuote$ | async) && part.isRushPart" class="col-12 border-top p-2 d-flex justify-content-center">
              <a id="partList_div_NationalInventory" class="hyperlink" href="javascript:void(0)" (click)="openNationalInventory(part)">National Inventory</a>
            </div>
          </div>
          <div style="width: 100%; text-align: center;" id="load-more-results-container">
            <button class="btn" (click)="continueBrowsingInStock(currentPage);">Load More Results</button>
          </div>
        </div>
        <div style="width: 100%; text-align: center;" *ngIf="loading">
          <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>


      </ng-template>
    </div>
  </div>
</div>
